ion-col{
   ion-input{
      border: solid 1px red;
   }
}

ion-row{
   ion-textarea{
      border: solid 1px red;
   }
}


ion-popover{
  ion-backdrop{
    opacity: 0.5 !important;
  }
  .popover-wrapper{
    .popover-content{
      width:100vw;
      max-width:700px;
    }
  }
}
